import React, { FC } from 'react';
import Autosuggest, { AutosuggestProps, InputProps } from 'react-autosuggest';
import { Input } from '@form/components/Input/Input.component';
import { MagnifierIcon } from '@icons/Magnifier.icon';
import { PlcColor } from '@styles/colors';

type Props = AutosuggestProps<any, any> & {
  rightIcon?: React.ReactNode;
  isLeftIconStyle?: boolean;
};

const Autocomplete: FC<Props> = ({
  renderInputComponent,
  focusInputOnSuggestionClick = false,
  rightIcon,
  isLeftIconStyle,
  ...rest
}) => {
  const defaultRenderInputComponent = (inputProps: InputProps<any>) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      inputProps.onChange(e, { method: 'type', newValue: e.target.value });
    };

    return (
      <Input
        icon={rightIcon ?? <MagnifierIcon fillColor={isLeftIconStyle ? PlcColor.GREEN : ''} height={24} width={24} />}
        isLeftIconStyle={isLeftIconStyle}
        {...inputProps}
        onChange={onChange}
        spellCheck={false}
      />
    );
  };

  const finalRenderInputComponent = renderInputComponent || defaultRenderInputComponent;

  return (
    <Autosuggest
      focusInputOnSuggestionClick={focusInputOnSuggestionClick}
      renderInputComponent={finalRenderInputComponent}
      {...rest}
    />
  );
};

export { Autocomplete };
