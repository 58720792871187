import { TFunction } from 'i18next';
import React, { ComponentPropsWithRef, FC, forwardRef, KeyboardEvent, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  GetSectionSuggestions,
  GetSuggestionValue,
  InputProps,
  OnSuggestionsClearRequested,
  OnSuggestionSelected,
  RenderSectionTitle,
  ShouldRenderSuggestions,
  SuggestionsFetchRequested,
} from 'react-autosuggest';
import { Trans } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchLocalities } from '@api/business/geogouv-api';
import { useAuthModal } from '@auth/business/AuthModal.hook';
import { BasicLink } from '@components/buttons/BasicLink/BasicLink.component';
import { UnderlinedButton } from '@components/buttons/FlatButton/FlatButton.component';
import { PlcInputLoader } from '@components/Loaders/PlcInputLoader.component';
import { Text } from '@components/Text/Text.component';
import { Autocomplete } from '@form/components/Autocomplete/Autocomplete.component';
import { HintProps } from '@form/components/FormHints/FormHints.component';
import { TinnyPinIcon } from '@icons/TinnyPin.icon';
import { useTranslation } from '@locales/useTranslation.hook';
import { MyPositionAsCity, recoverCityFromQuery } from '@meteo/business/Location.utils';
import { useLocation } from '@meteo/business/LocationSelector.hook';
import { getLastCity, isGeolocation, setCityToAdd, setLastCity } from '@meteo/business/meteo.utils';
import { City, Geolocation } from '@meteo/types';
import { SettingsTabEnum } from '@settings/business/Settings.type';
import { PlcDispatch, RootState } from '@store/store';
import { usePlcRouter } from '@utils/customHooks';
import { useDebounce } from '@utils/debounce.hook';
import { fromCityToUrlSlug, getPreferencesLinkProps } from '@utils/url';
import classnames from 'classnames';

import styles from './MeteoCityAutocomplete.module.scss';
import { SpinnerIcon } from '@components/icons/Spinner.icon';

type Section = {
  title: string | null;
  cities: City[];
};

export type MeteoCityAutocompleteProps = Omit<ComponentPropsWithRef<'div'>, 'onSelect'> &
  HintProps & {
    includeMyPosition?: boolean;
    isLeftIconStyle?:boolean;
    current?: City;
    onSelect?: (city?: City) => void;
    withConnectSuggestion?: boolean;
    inputId?: string;
    useLastCity?: boolean;
    useFavoriteSuggestions?: boolean;
  };

function getDefaultSuggestions(withGeolocation: boolean, favorites: City[], t: TFunction): Section[] {
  const suggestions = [];
  
  if (withGeolocation) {
    suggestions.push({
      cities: [MyPositionAsCity],
      title: null,
    });
  }

  if (favorites && favorites.length > 0) {
    suggestions.push({
      cities: favorites,
      title: t('common.meteo.autocomplete.favorites-label'),
    });
  }

  return suggestions;
}

function renderSuggestion(suggestion: Geolocation) {
  const {
    properties: { label, name, postcode },
  } = suggestion;
  return isGeolocation(suggestion) ? (
    <Text className="flex align-center" variant="small">
      <TinnyPinIcon className="plc-mr-s" height={24} width={24} />
      {name}
    </Text>
  ) : (
    <Text variant="small">
      {label ? label : name} ({postcode})
    </Text>
  );
}

const MeteoCityAutocomplete: FC<MeteoCityAutocompleteProps> = forwardRef(
  (
    {
      includeMyPosition = true,
      isLeftIconStyle,
      current,
      onSelect,
      withConnectSuggestion = false,
      inputId,
      className,
      validators,
      isHintVisible,
      useLastCity = true,
      useFavoriteSuggestions = true,
      ...rest
    },
    meteoCityAutocompleteRef
  ) => {
    const lastCity = useMemo(() => (useLastCity ? getLastCity() : undefined), []);
    const { t } = useTranslation(['common', 'meteo']);
    const favoriteLocationsFromStore = useSelector((state: RootState) => state.location.favoriteLocations);
    const favoriteLocations = useFavoriteSuggestions ? favoriteLocationsFromStore : [];
    const {
      location: { setCurrentLocation },
    } = useDispatch<PlcDispatch>();

    const { isGeolocationDenied, geolocationError } = useLocation(current || lastCity);
    const withGeolocation = includeMyPosition && !isGeolocationDenied;
    const defaultSuggestions = useMemo(() => getDefaultSuggestions(withGeolocation, favoriteLocations, t), [
      withGeolocation,
      favoriteLocations,
      t,
    ]);
    // Input value
    const [value, setValue] = useState<string>(
      // eslint-disable-next-line no-nested-ternary
      current ? current.properties.name : lastCity ? lastCity.properties.name : ''
    );

    // Term to search city
    const [searchTerm, setSearchTerm] = useState('');
    // Suggestions in autocomplete list
    const [suggestions, setSuggestions] = useState<Section[]>(defaultSuggestions);
    const [rightIcon, setRightIcon] = useState<ReactNode | undefined>(undefined);
    // Selected suggestion by user
    const [selection, setSelection] = useState<City | undefined>(current || lastCity);
    // Should display the link to add to favorites
    const [shouldDisplayAddToFavoriteLink, setShouldDisplayAddToFavoriteLink] = useState(false);
    const { showSignInModal } = useAuthModal();
    const { isAuthenticated } = useSelector((state: RootState) => state.auth);
    const isGeolocationSelected = useMemo(() => selection && isGeolocation(selection), [selection]);
    const router = usePlcRouter();

    function resetField() {
      setValue('');
      setSelection(undefined);
      setSuggestions(defaultSuggestions);
    }

    // On select callback
    useEffect(() => {
      (async function updateSelection() {
        let newCity = selection;
        if (newCity && !newCity.properties.context) {
          // Case when the selection clicked is from favorites or is the current position
          newCity = await recoverCityFromQuery(fromCityToUrlSlug(newCity));
        }
        if (!newCity) return;
        setLastCity(newCity);
        setCurrentLocation(newCity);
        if (onSelect) {
          onSelect(newCity);
        }
      })();
    }, [selection]);

    // Remove selection if geolocation selected but disabled
    useEffect(() => {
      if (!withGeolocation && isGeolocationSelected) {
        resetField();
      }
    }, [withGeolocation, isGeolocationSelected]);

    useEffect(() => {
      if (current) setValue(current.properties.name);
    }, [current]);

    // Effect to determine if we should display an add to favorite link below the field
    useEffect(() => {
      
      if (isAuthenticated && selection && favoriteLocations) {
        const favoritesEqualToSelection = favoriteLocations.filter(
          favorite =>
            selection.properties.name === favorite.properties.name &&
            selection.properties.postcode === favorite.properties.postcode
        );
        setShouldDisplayAddToFavoriteLink(favoritesEqualToSelection.length === 0);
      } else {
        setShouldDisplayAddToFavoriteLink(false);
      }
    }, [isAuthenticated, favoriteLocations, selection]);

    const onInputChange: InputProps<City>['onChange'] = (_, { newValue }) => {
      setValue(newValue);
    };

    const queryString = useMemo(() => ({ queryString: searchTerm }), [searchTerm]);
    const cities = useSearchLocalities(useDebounce(queryString, 300));
    
    let onChange: boolean = false;
    useEffect(() => {
      setSuggestions([{ cities, title: null }]);
      onChange = false
      setRightIcon(undefined)
    }, [cities]);

    const onSuggestionsFetchRequested: SuggestionsFetchRequested = ({ value: val, reason }) => {
      onChange = true;
      if (suggestions[0] === undefined) onChange = false;
      if (val.length === 0 || reason === 'input-focused') {
        resetField();
        onChange = false;
      }   
      else if (!isGeolocationSelected) {
        if (onChange === true){
          setRightIcon(<SpinnerIcon height={44} width={44}/>);
        }else{
          setRightIcon(undefined)
        }
        setSuggestions([]);
        setSearchTerm(val); 
      }
      onChange = false;
    };
    const onSuggestionsClearRequested: OnSuggestionsClearRequested = () => {
      setSuggestions([]);
    };

    const getSuggestionValue: GetSuggestionValue<City> = suggestion => {
      return suggestion.properties.name;
    };

    const shouldRenderSuggestions: ShouldRenderSuggestions = val => {
      return val.length === 0 || val.trim().length > 2;
    };

    const renderSectionTitle: RenderSectionTitle = section => {
      return section.title ? (
        <Text flavour="grey" variant="h6">
          {section.title}
        </Text>
      ) : null;
    };

    const getSectionSuggestions: GetSectionSuggestions<City, Section> = section => {
      if (section){
        return section.cities;
      }
      return cities
    };

    const onSuggestionSelected: OnSuggestionSelected<City> = (_, { suggestion }) => {
      setSelection(suggestion);
    };

    function onKeyDown(e: KeyboardEvent<HTMLInputElement>) {
      // Clear the field if the user is in geolocation mode and wants to change
      const deleteKeys = ['Backspace', 'Delete'];
      if (deleteKeys.includes(e.key)) {
        if (isGeolocationSelected) {
          resetField();
        }
        setSelection(undefined);
      } else if (e.key.length === 1) {
        // Clear the selection if the user types a character in the field instad of choosing from the suggestions
        setSelection(undefined);
      }
    }

    function addToFavorite() {
      if (selection) {
        setCityToAdd(selection);
        const meteoPreferencesLinkProps = getPreferencesLinkProps(SettingsTabEnum.forecast);
        router.push(meteoPreferencesLinkProps.href, meteoPreferencesLinkProps.as);
      }
    }

    const hasError = Boolean(geolocationError);
    const defaultValidator = [
      {
        isValid: !hasError,
        message: geolocationError || '',
      },
    ];

    const inputProps = {
      id: inputId === '' ? undefined : inputId || 'meteo-city-autocomplete-input',
      isHintVisible: validators ? isHintVisible : hasError,
      onChange: onInputChange,
      onKeyDown,
      placeholder: t('common.meteo.autocomplete.placeholder'),
      validators: validators || defaultValidator,
      value,
    };
    const isValid = inputProps.validators.every(v => v.isValid);
    const rootClassName = classnames(styles['meteo-city-autocomplete'], className);
    return (
      <div ref={meteoCityAutocompleteRef} className={rootClassName} {...rest}>
        <Autocomplete
          getSectionSuggestions={getSectionSuggestions}
          getSuggestionValue={getSuggestionValue}
          inputProps={inputProps}
          isLeftIconStyle={isLeftIconStyle}
          multiSection
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          renderSectionTitle={renderSectionTitle}
          renderSuggestion={renderSuggestion}
          shouldRenderSuggestions={shouldRenderSuggestions}
          suggestions={suggestions}
          rightIcon={rightIcon}
        />
        {withConnectSuggestion && (
          <div className="meteo-city-autocomplete-signin">
            {!isAuthenticated && (
              <Text className="plc-mt-xs" variant="tiny">
                <Trans i18nKey="common.meteo.autocomplete.signin-link" ns="common">
                  <BasicLink
                    isUnderlined
                    onClick={() => showSignInModal()}
                    textProps={{
                      flavour: 'grey',
                      variant: 'tiny',
                    }}
                  >
                    Connect
                  </BasicLink>
                  to get favorites
                </Trans>
              </Text>
            )}
            {shouldDisplayAddToFavoriteLink && isValid && (
              <div className="plc-mt-xs">
                <UnderlinedButton
                  onClick={addToFavorite}
                  textProps={{
                    flavour: 'grey',
                    i18nKey: 'common.meteo.add-to-favorite',
                    variant: 'tiny',
                  }}
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

const MeteoCityAutocompleteLoader: FC<MeteoCityAutocompleteProps> = ({ className }) => {
  const rootClassName = classnames(styles['meteo-city-autocomplete'], className);
  return (
    <span className={rootClassName}>
      <PlcInputLoader fixedWidth uniqueKey="MeteoCityAutoComplete" withLabel={false} />
    </span>
  );
};
export { MeteoCityAutocomplete, MeteoCityAutocompleteLoader };
